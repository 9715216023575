.banner-container {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-family: "Great Vibes";
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  word-spacing: 1rem;
  color: #321;
}

.banner-container > h1 {
  height: 0px;
  z-index: 100;
  position: relative;
  top: 3rem;
  margin: 0 0;
}

.banner-image {
  max-width: 100%;
  width: 100%;
  min-height: 170px;
}

@media screen and (max-width: 600px) {
  .banner-container > h1 {
    text-shadow: 0.07em 0 white, 0 0.07em white, -0.07em 0 white,
      0 -0.07em white;
  }
}
