html {
  font-size: 1vw;
  box-sizing: border-box;
  overflow: hidden;
  height: max-content;
}

body {
  margin: 0;
  box-sizing: inherit;
  overflow: hidden;
  height: max-content;
}

#root {
  box-sizing: inherit;
  overflow: hidden;
  height: max-content;
}

.App {
  overflow: auto;
  height: 100vh;
  overflow-x: hidden;
}

.scroll-snap-item {
  scroll-snap-align: start;
  box-sizing: border-box;
  z-index: 1;
}

@font-face {
  font-family: "Great Vibes";
  src: url("./assets/fonts/GreatVibes-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Marck Script";
  src: url("./assets/fonts/MarckScript-Regular.ttf") format("truetype");
}

@media screen and (max-width: 576px) {
  html {
    font-size: 0.4rem;
    outline: 1px solid green;
  }
}
