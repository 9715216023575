ul {
    margin: 0;
  }
  ul.dashed {
    list-style-type: none;
    font-style: italic;
  }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "- ";
    text-indent: -5px;
  }