.profile-container {
  display: flex;
  align-items: center;
  background-image: url("https://w0.peakpx.com/wallpaper/680/503/HD-wallpaper-navy-blue-geometric-navy-blue.jpg");
  background-position: 8%;
  color: white;
  width: 100%;
  padding: 2vw 0;
  scroll-snap-align: start;
}

.profile-image {
  display: table-cell;

  max-width: 100%;
  max-height: 100%;
  width: 16vw;
  margin-left: 7rem;
  min-width: 100px;
}

.main-thought-container {
  display: table-cell;
  vertical-align: middle;
  margin-left: 2rem;
}

.main-thought {
  font-family: "Marck Script";
  display: block;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.main-thought-author {
  font-family: "Great Vibes";
  display: block;
  margin-left: 52rem;
  font-size: 2rem;
  font-style: italic;
  letter-spacing: 0.25rem;
}

.main-thought-author::before {
  content: "_";
  position: relative;
  top: -1rem;
  left: -0.3rem;
}

.main-thought-container {
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

.main-thought {
  -webkit-animation-name: mainThoughtBig;
          animation-name: mainThoughtBig;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes mainThoughtBig {
  from {
    font-size: 1rem;
    margin-left: 20rem;
  }
  to {
    font-size: 3rem;
    margin-left: 0rem;
  }
}

@keyframes mainThoughtBig {
  from {
    font-size: 1rem;
    margin-left: 20rem;
  }
  to {
    font-size: 3rem;
    margin-left: 0rem;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .profile-image {
    margin-left: 0.5rem;
  }
  .main-thought-container {
    width: 65%;
  }

  .main-thought {
    width: 75%;
    margin-left: auto;
    margin-right: 3rem;
    font-size: 2.5rem;
  }
  .main-thought-author {
    margin-left: auto;
    margin-right: 13rem;
    width: 2rem;
  }
}

.banner-container {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-family: "Great Vibes";
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  word-spacing: 1rem;
  color: #321;
}

.banner-container > h1 {
  height: 0px;
  z-index: 100;
  position: relative;
  top: 3rem;
  margin: 0 0;
}

.banner-image {
  max-width: 100%;
  width: 100%;
  min-height: 170px;
}

@media screen and (max-width: 600px) {
  .banner-container > h1 {
    text-shadow: 0.07em 0 white, 0 0.07em white, -0.07em 0 white,
      0 -0.07em white;
  }
}

ul {
    margin: 0;
  }
  ul.dashed {
    list-style-type: none;
    font-style: italic;
  }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "- ";
    text-indent: -5px;
  }
.rod-container {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: none !important;
  padding-top: 3rem;
  padding-bottom: 6rem;
  /* height: 100%; */
  -webkit-filter: opacity(100%);
          filter: opacity(100%);
  /* padding-bottom: 50rem; */
  /* scroll-snap-align: start; */
}

.rod-container > .rod-container-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rod-info {
  display: block;
  width: 95vw;
}

.rod-info > h1 {
  font-weight: normal;
  padding: 0.2rem 5rem;
  font-family: "Great Vibes";
  letter-spacing: 0.3rem;
  word-spacing: 0.8rem;
  font-size: 3rem;
}

/* .rod-info  .nbstr_btn {
  margin-left: auto;
} */

.rod-info > ul {
  margin-left: 3rem;
  -webkit-filter: opacity(90%);
          filter: opacity(90%);
}

.rod-info > .scrollable-ul {
  height: 70%;
}

.rod-info > ul > li {
  position: relative;
  list-style: none;
  font-style: italic;
  font-size: 1.5rem;
  margin: 2rem 0;
  display: flex;
  padding-bottom: 0.6rem;
  padding-right: 4rem;
  margin-left: 2rem;
  margin: 1rem auto;
  width: 90%;
  /* width: 100%; */
  border-radius: 2px;
}

.rod-info > ul > li > .img-container {
  width: max(15%, 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  justify-content: center;
}

.rod-info > ul > li > .text-container {
  padding: 1.5rem 1rem;
  padding-left: 8rem;
  background-color: white;
  box-shadow: 3px 3px 1px black;
  margin-left: 2rem;
}

.nbstr_btn {
  display: block !important;
  margin-left: auto;
}

.rod-info > hr {
  margin-left: 5rem;
}

.rod-icon {
  /* margin: 1rem; */
  position: absolute;
  max-width: 10rem;
  max-height: 10rem;
  width: 9vw;
  height: 9vw;
  /* border: 1px solid black; */
  padding: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;

  left: 0;
  top: 50%;
  -webkit-transform: translate(-25%, -52.5%);
          transform: translate(-25%, -52.5%);
  /* max-height: 10rem;
  max-width: 10rem; */
  /* width: 80%; */
  /* width: 5vw;
  height: 60%;
  position: relative;
  left: 50%;
  background-color: white;
  box-shadow: 0px 0px 10px 1px grey;
  padding: 0.5rem;
  margin-left: auto;

  display: inline-block; */
}

.header {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
}
.header > .rod-header-icon {
  display: block;
  max-width: 70px;
  width: 7vw;

  margin: 0.5rem 0;
  margin-left: 6rem;
}

.header > h1 {
  margin: 0;
  margin-left: 3rem;
  color: white;

  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
}

.text-container > span {
  line-height: 0.5rem;
}

@media screen and (max-width: 1300px) {
  .rod-info > ul > li {
    line-height: 4rem;

    display: flex;
    padding: 1rem 0;
    padding-right: 4rem;
    width: 90%;
    border-radius: 2px;
  }
}

@media screen and (max-width: 768px) {
  .rod-info {
    width: 100%;
  }
  .rod-info > ul {
    margin: 0 auto;
    /* margin: 0; */
    /* margin-left: 2rem; */
    padding: 0;
    width: 90vw;
  }

  .rod-info > ul > li {
    margin-left: 0;
    padding-right: 3rem;
    width: 100%;
  }

  .rod-info > ul > li > .text-container {
    width: 130%;

    /* padding-left: 4.5rem; */
    padding-right: 2.5rem;
    margin-right: 1.5rem;
  }

  .rod-info > ul > li > .text-container > p {
    margin-right: 1.5rem;
    line-height: 4vw;
  }

  .rod-icon {
    height: 12vw;
    width: 12vw;
  }
}

@media screen and (max-width: 576px) {
  .rod-info {
    position: relative;
    /* height: 300px; */
  }

  .rod-info > .header {
    padding-top: 1rem;
  }

  .rod-info > h1 {
    height: 90px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .rod-info > ul {
    width: 90vw;
  }

  .rod-icon {
    height: 25vw;
    width: 25vw;
  }
  .rod-info > ul > li {
    padding-right: 0rem;
    margin-left: 4rem;
    /* margin-right: 10rem; */
    width: 90%;
  }
}

.sigt {
  text-align: center;
}

.sig {
  margin-top: 40px;
  margin-right: 10rem;
  text-align: right;
}

.signature {
  margin-top: 20px;
  position: relative;
  right: -10px;
  top: -20px;
  font-family: 'Brush Script MT', cursive;
  letter-spacing: 2px;
}
.navigation {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  width: 100%;
  z-index: 10000;
}
.navigation > ul {
  list-style-type: none;
  margin: 0 auto;
  color: white;
  background: #234;
  max-width: 100vw;
  text-align: center;
}

.navigation > ul > li {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  font-family: "Calibri";
  display: inline-block;
  padding: 0.5rem 0.5rem;
  margin: 0.1rem 2rem;
}

.navigation > ul li:hover {
  color: #78a;
  cursor: pointer;
}

.navigation-absolute {
  position: fixed;
}

@media screen and (max-width: 576px) {
  .navigation > ul {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.7rem;
  }

  .navigation > ul > li {
    margin: 0;
    padding: 0;
    font-size: 8px;
  }
}

@media screen and (min-width: 1200px) {
  .navigation > ul > li {
    font-size: 1.3rem;
    padding: 0.2rem 0.5rem;
  }
}

footer {
  font-family: "Poppins", sans-serif;
  display: block;
  background-color: #444;
  margin-top: 30px;
}

.footer-contact {
  margin: 0 auto;
  width: 76%;
  padding-top: 20px;
  color: #f0f0f0;
  letter-spacing: 3px;
  font-size: 16px;
  padding-bottom: 20px;
}

.footer-contact .main {
  height: 54px;
}

.footer-contact .sub {
  padding-bottom: 10px;
}

.footer-contact svg {
  position: relative;
  top: -1px;
  width: 1.1em;
  height: 1.1em;
}

.footer-contact p {
  letter-spacing: 2px;
  font-weight: 100;
  margin-left: 2px;
  margin-right: 5px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #fff;
}

.footer-contact a {
  color: #fff;
}

.footer-contact .logo {
  position: relative;
  top: -4px;
  padding: 0;
  margin-bottom: 10px;
}

.footer-contact img {
  -webkit-filter: sepia(100%);
          filter: sepia(100%);
  max-width: 58px;
}

.footer-contact .nav-text-sub {
  font-size: 10px;
  letter-spacing: 2px;
}

.footer-contact .nav-text-main {
  font-size: 15px;
  letter-spacing: 3px;
}

.footer-contact .underline {
  position: relative;
  padding-bottom: 6px;
}

.footer-contact .underline:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 105%; /* or 100px */
  border-bottom: 1px solid rgb(22, 166, 223);
}

.footer-contact .sub .smaller {
  font-size: 10px;
  max-width: 300px;
}

.footer-contact .map iframe {
  width: 100%;
  min-width: 20vw;
  border: none;
  border-radius: 10px;
}

.move-right {
  position: relative;
  left: 5vw;
}

@media screen and (max-width: 768px) {
  .footer-contact .nl {
    position: relative;
    top: 10px;
  }
}

@media screen and (max-width: 1052px) {
  .open-hours {
    padding-right: 7px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .open-hours {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (min-width: 992px) {
  .footer-contact {
    margin: 0;
    width: 90%;
    margin-right: auto;
  }
  footer .map {
    margin-left: auto;
  }
}

.copyright {
    padding: 10px;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    color: #f0f0f0;
    background-color: #555;
}

.copyright p {
    margin: 0;
}
.contact-container {
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  /* width: 100%; */
  text-align: center;
  font-family: var(--font-serif);
  font-size: 20px;
  margin-bottom: 30px;
  margin-right: 5rem;
  box-sizing: border-box;
}

.contact-icon {
  display: flex;
  background-color: #333;
  color: #fff;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
}

.contact-icon {
  font-size: 2rem;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-border {
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 5px;
}

.contact-card {
  margin-top: 20px;
  height: 100px;
}

.wd70 {
  width: 80%;
}
.h250 {
  height: 300px;
}

.contact-title {
  color: #000 !important;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 10px;
}

.card-border p {
  color: #555;
  margin: 0;
  font-style: italic;
}

.card-border a {
  color: #000;
}

.contact-container iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 10px;
  /* position: relative;
  top: -10px; */
}

.contact-form {
  font-family: var(--font-serif);
  padding-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
  border-radius: 3px;
  border: 1px solid grey;
  margin: 0 0 20px 0;
  padding: 12px 20px;
  font-size: 20px;
  width: 100%;
  resize: none;
}

.contact-form textarea {
  height: 300px;
}

.contact-form button {
  width: 100%;
  padding: 10px 0px;
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 0 10px 0;
  color: white;
  background-color: black;
  border: none;
  border-radius: 10px;
  transition: background-color 1s linear;
  border: 1px solid white;
}

.contact-form button:hover {
  background-color: white;
  border-color: grey;
}

.envelope-closed {
  color: black !important;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  position: relative;
  left: -12px;
}

.envelope-open {
  transition: opacity 0.2s ease-in;
  position: relative;
  left: 12px;
  top: -3px;
}

.contact-form button:hover .envelope-closed {
  opacity: 1;
  color: var(--secondary-lighter-color);
}

.contact-form button:hover .envelope-open {
  opacity: 0;
}

.sender-error {
  color: #f00 !important;
  height: 54px;
}

.sender-success {
  color: #2bad60 !important;
  height: 54px;
}

.nbstr_card {
  margin-bottom: 4rem;
}

@media screen and (min-width: 992px) {
  .contact-container {
    padding-top: 60px;
    font-size: 18px;
  }

  .contact-card {
    margin-top: 5px;
  }

  .h250 {
    height: 255px;
  }

  .nbstr_card {
    margin-bottom: 5px;
  }

  .nbstr_card > .card-border {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-container iframe {
    height: 450px;
  }

  .contact-form textarea {
    height: 220px;
  }
}

@media screen and (max-width: 992px) {
  .wd70 {
    width: 100%;
  }
}

html {
  font-size: 1vw;
  box-sizing: border-box;
  overflow: hidden;
  height: -webkit-max-content;
  height: max-content;
}

body {
  margin: 0;
  box-sizing: inherit;
  overflow: hidden;
  height: -webkit-max-content;
  height: max-content;
}

#root {
  box-sizing: inherit;
  overflow: hidden;
  height: -webkit-max-content;
  height: max-content;
}

.App {
  overflow: auto;
  height: 100vh;
  overflow-x: hidden;
}

.scroll-snap-item {
  scroll-snap-align: start;
  box-sizing: border-box;
  z-index: 1;
}

@font-face {
  font-family: "Great Vibes";
  src: url(/static/media/GreatVibes-Regular.4bd0ed00.ttf) format("truetype");
}

@font-face {
  font-family: "Marck Script";
  src: url(/static/media/MarckScript-Regular.48d8d53d.ttf) format("truetype");
}

@media screen and (max-width: 576px) {
  html {
    font-size: 0.4rem;
    outline: 1px solid green;
  }
}

