.rod-container {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: none !important;
  padding-top: 3rem;
  padding-bottom: 6rem;
  /* height: 100%; */
  filter: opacity(100%);
  /* padding-bottom: 50rem; */
  /* scroll-snap-align: start; */
}

.rod-container > .rod-container-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rod-info {
  display: block;
  width: 95vw;
}

.rod-info > h1 {
  font-weight: normal;
  padding: 0.2rem 5rem;
  font-family: "Great Vibes";
  letter-spacing: 0.3rem;
  word-spacing: 0.8rem;
  font-size: 3rem;
}

/* .rod-info  .nbstr_btn {
  margin-left: auto;
} */

.rod-info > ul {
  margin-left: 3rem;
  filter: opacity(90%);
}

.rod-info > .scrollable-ul {
  height: 70%;
}

.rod-info > ul > li {
  position: relative;
  list-style: none;
  font-style: italic;
  font-size: 1.5rem;
  margin: 2rem 0;
  display: flex;
  padding-bottom: 0.6rem;
  padding-right: 4rem;
  margin-left: 2rem;
  margin: 1rem auto;
  width: 90%;
  /* width: 100%; */
  border-radius: 2px;
}

.rod-info > ul > li > .img-container {
  width: max(15%, 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  justify-content: center;
}

.rod-info > ul > li > .text-container {
  padding: 1.5rem 1rem;
  padding-left: 8rem;
  background-color: white;
  box-shadow: 3px 3px 1px black;
  margin-left: 2rem;
}

.nbstr_btn {
  display: block !important;
  margin-left: auto;
}

.rod-info > hr {
  margin-left: 5rem;
}

.rod-icon {
  /* margin: 1rem; */
  position: absolute;
  max-width: 10rem;
  max-height: 10rem;
  width: 9vw;
  height: 9vw;
  /* border: 1px solid black; */
  padding: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;

  left: 0;
  top: 50%;
  transform: translate(-25%, -52.5%);
  /* max-height: 10rem;
  max-width: 10rem; */
  /* width: 80%; */
  /* width: 5vw;
  height: 60%;
  position: relative;
  left: 50%;
  background-color: white;
  box-shadow: 0px 0px 10px 1px grey;
  padding: 0.5rem;
  margin-left: auto;

  display: inline-block; */
}

.header {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
}
.header > .rod-header-icon {
  display: block;
  max-width: 70px;
  width: 7vw;

  margin: 0.5rem 0;
  margin-left: 6rem;
}

.header > h1 {
  margin: 0;
  margin-left: 3rem;
  color: white;

  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
}

.text-container > span {
  line-height: 0.5rem;
}

@media screen and (max-width: 1300px) {
  .rod-info > ul > li {
    line-height: 4rem;

    display: flex;
    padding: 1rem 0;
    padding-right: 4rem;
    width: 90%;
    border-radius: 2px;
  }
}

@media screen and (max-width: 768px) {
  .rod-info {
    width: 100%;
  }
  .rod-info > ul {
    margin: 0 auto;
    /* margin: 0; */
    /* margin-left: 2rem; */
    padding: 0;
    width: 90vw;
  }

  .rod-info > ul > li {
    margin-left: 0;
    padding-right: 3rem;
    width: 100%;
  }

  .rod-info > ul > li > .text-container {
    width: 130%;

    /* padding-left: 4.5rem; */
    padding-right: 2.5rem;
    margin-right: 1.5rem;
  }

  .rod-info > ul > li > .text-container > p {
    margin-right: 1.5rem;
    line-height: 4vw;
  }

  .rod-icon {
    height: 12vw;
    width: 12vw;
  }
}

@media screen and (max-width: 576px) {
  .rod-info {
    position: relative;
    /* height: 300px; */
  }

  .rod-info > .header {
    padding-top: 1rem;
  }

  .rod-info > h1 {
    height: 90px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .rod-info > ul {
    width: 90vw;
  }

  .rod-icon {
    height: 25vw;
    width: 25vw;
  }
  .rod-info > ul > li {
    padding-right: 0rem;
    margin-left: 4rem;
    /* margin-right: 10rem; */
    width: 90%;
  }
}

.sigt {
  text-align: center;
}

.sig {
  margin-top: 40px;
  margin-right: 10rem;
  text-align: right;
}

.signature {
  margin-top: 20px;
  position: relative;
  right: -10px;
  top: -20px;
  font-family: 'Brush Script MT', cursive;
  letter-spacing: 2px;
}