.contact-container {
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  /* width: 100%; */
  text-align: center;
  font-family: var(--font-serif);
  font-size: 20px;
  margin-bottom: 30px;
  margin-right: 5rem;
  box-sizing: border-box;
}

.contact-icon {
  display: flex;
  background-color: #333;
  color: #fff;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
}

.contact-icon {
  font-size: 2rem;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-border {
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 5px;
}

.contact-card {
  margin-top: 20px;
  height: 100px;
}

.wd70 {
  width: 80%;
}
.h250 {
  height: 300px;
}

.contact-title {
  color: #000 !important;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 10px;
}

.card-border p {
  color: #555;
  margin: 0;
  font-style: italic;
}

.card-border a {
  color: #000;
}

.contact-container iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 10px;
  /* position: relative;
  top: -10px; */
}

.contact-form {
  font-family: var(--font-serif);
  padding-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
  border-radius: 3px;
  border: 1px solid grey;
  margin: 0 0 20px 0;
  padding: 12px 20px;
  font-size: 20px;
  width: 100%;
  resize: none;
}

.contact-form textarea {
  height: 300px;
}

.contact-form button {
  width: 100%;
  padding: 10px 0px;
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 0 10px 0;
  color: white;
  background-color: black;
  border: none;
  border-radius: 10px;
  transition: background-color 1s linear;
  border: 1px solid white;
}

.contact-form button:hover {
  background-color: white;
  border-color: grey;
}

.envelope-closed {
  color: black !important;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  position: relative;
  left: -12px;
}

.envelope-open {
  transition: opacity 0.2s ease-in;
  position: relative;
  left: 12px;
  top: -3px;
}

.contact-form button:hover .envelope-closed {
  opacity: 1;
  color: var(--secondary-lighter-color);
}

.contact-form button:hover .envelope-open {
  opacity: 0;
}

.sender-error {
  color: #f00 !important;
  height: 54px;
}

.sender-success {
  color: #2bad60 !important;
  height: 54px;
}

.nbstr_card {
  margin-bottom: 4rem;
}

@media screen and (min-width: 992px) {
  .contact-container {
    padding-top: 60px;
    font-size: 18px;
  }

  .contact-card {
    margin-top: 5px;
  }

  .h250 {
    height: 255px;
  }

  .nbstr_card {
    margin-bottom: 5px;
  }

  .nbstr_card > .card-border {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-container iframe {
    height: 450px;
  }

  .contact-form textarea {
    height: 220px;
  }
}

@media screen and (max-width: 992px) {
  .wd70 {
    width: 100%;
  }
}
