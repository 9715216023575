.profile-container {
  display: flex;
  align-items: center;
  background-image: url("https://w0.peakpx.com/wallpaper/680/503/HD-wallpaper-navy-blue-geometric-navy-blue.jpg");
  background-position: 8%;
  color: white;
  width: 100%;
  padding: 2vw 0;
  scroll-snap-align: start;
}

.profile-image {
  display: table-cell;

  max-width: 100%;
  max-height: 100%;
  width: 16vw;
  margin-left: 7rem;
  min-width: 100px;
}

.main-thought-container {
  display: table-cell;
  vertical-align: middle;
  margin-left: 2rem;
}

.main-thought {
  font-family: "Marck Script";
  display: block;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.main-thought-author {
  font-family: "Great Vibes";
  display: block;
  margin-left: 52rem;
  font-size: 2rem;
  font-style: italic;
  letter-spacing: 0.25rem;
}

.main-thought-author::before {
  content: "_";
  position: relative;
  top: -1rem;
  left: -0.3rem;
}

.main-thought-container {
  animation-name: fadein;
  animation-duration: 4s;
}

.main-thought {
  animation-name: mainThoughtBig;
  animation-duration: 2s;
}

@keyframes mainThoughtBig {
  from {
    font-size: 1rem;
    margin-left: 20rem;
  }
  to {
    font-size: 3rem;
    margin-left: 0rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .profile-image {
    margin-left: 0.5rem;
  }
  .main-thought-container {
    width: 65%;
  }

  .main-thought {
    width: 75%;
    margin-left: auto;
    margin-right: 3rem;
    font-size: 2.5rem;
  }
  .main-thought-author {
    margin-left: auto;
    margin-right: 13rem;
    width: 2rem;
  }
}
