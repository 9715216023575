footer {
  font-family: "Poppins", sans-serif;
  display: block;
  background-color: #444;
  margin-top: 30px;
}

.footer-contact {
  margin: 0 auto;
  width: 76%;
  padding-top: 20px;
  color: #f0f0f0;
  letter-spacing: 3px;
  font-size: 16px;
  padding-bottom: 20px;
}

.footer-contact .main {
  height: 54px;
}

.footer-contact .sub {
  padding-bottom: 10px;
}

.footer-contact svg {
  position: relative;
  top: -1px;
  width: 1.1em;
  height: 1.1em;
}

.footer-contact p {
  letter-spacing: 2px;
  font-weight: 100;
  margin-left: 2px;
  margin-right: 5px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #fff;
}

.footer-contact a {
  color: #fff;
}

.footer-contact .logo {
  position: relative;
  top: -4px;
  padding: 0;
  margin-bottom: 10px;
}

.footer-contact img {
  filter: sepia(100%);
  max-width: 58px;
}

.footer-contact .nav-text-sub {
  font-size: 10px;
  letter-spacing: 2px;
}

.footer-contact .nav-text-main {
  font-size: 15px;
  letter-spacing: 3px;
}

.footer-contact .underline {
  position: relative;
  padding-bottom: 6px;
}

.footer-contact .underline:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 105%; /* or 100px */
  border-bottom: 1px solid rgb(22, 166, 223);
}

.footer-contact .sub .smaller {
  font-size: 10px;
  max-width: 300px;
}

.footer-contact .map iframe {
  width: 100%;
  min-width: 20vw;
  border: none;
  border-radius: 10px;
}

.move-right {
  position: relative;
  left: 5vw;
}

@media screen and (max-width: 768px) {
  .footer-contact .nl {
    position: relative;
    top: 10px;
  }
}

@media screen and (max-width: 1052px) {
  .open-hours {
    padding-right: 7px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .open-hours {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (min-width: 992px) {
  .footer-contact {
    margin: 0;
    width: 90%;
    margin-right: auto;
  }
  footer .map {
    margin-left: auto;
  }
}
