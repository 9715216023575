.copyright {
    padding: 10px;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    color: #f0f0f0;
    background-color: #555;
}

.copyright p {
    margin: 0;
}