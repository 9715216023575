.navigation {
  position: sticky;
  top: -1px;
  width: 100%;
  z-index: 10000;
}
.navigation > ul {
  list-style-type: none;
  margin: 0 auto;
  color: white;
  background: #234;
  max-width: 100vw;
  text-align: center;
}

.navigation > ul > li {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  font-family: "Calibri";
  display: inline-block;
  padding: 0.5rem 0.5rem;
  margin: 0.1rem 2rem;
}

.navigation > ul li:hover {
  color: #78a;
  cursor: pointer;
}

.navigation-absolute {
  position: fixed;
}

@media screen and (max-width: 576px) {
  .navigation > ul {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.7rem;
  }

  .navigation > ul > li {
    margin: 0;
    padding: 0;
    font-size: 8px;
  }
}

@media screen and (min-width: 1200px) {
  .navigation > ul > li {
    font-size: 1.3rem;
    padding: 0.2rem 0.5rem;
  }
}
